var echarts = require('echarts');
import tdTheme from './theme.json';
import { off, on } from '@/utils/tool';
echarts.registerTheme('tdTheme', tdTheme);
export default {
  props: {
    title: String,
    tooltip: {
      type: Boolean,
      default: true
    },
    loading: {
      type: Boolean,
      default: true
    }
  },
  data: function data() {
    return {
      currentValue: this.value,
      currentLoading: this.loading,
      dom: null,
      dialogOption: null
    };
  },
  watch: {
    value: function value(val) {
      this.currentValue = val;
      if (this.currentValue) {
        this.initChart();
      }
    }
  },
  methods: {
    resize: function resize() {
      this.dom && this.dom.resize();
    },
    initChart: function initChart() {
      var _this = this;
      if (this.dom) {
        this.dom.dispose();
        this.dom = null;
      }
      this.$nextTick(function () {
        _this.dom = echarts.init(_this.$refs.dom);
        _this.dom.setOption(_this.getOption());
        _this.dom.off('click');
        _this.dom.on('click', function (param) {
          _this.$emit("areaSalesApply", _this.getDiolagOption(), _this.title + " Tops");
        });
        on(window, 'resize', _this.resize);
      });
    }
  },
  beforeDestroy: function beforeDestroy() {
    off(window, 'resize', this.resize);
  }
};