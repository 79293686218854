import ajax from '@/utils/ajax';

// 左边便民设施类型列表
export function getFacilitiesType(data) {
  return ajax({
    url: '/digital/facilities/getFacilitiesType',
    method: 'post',
    data: data
  });
}

// 地图中间的各个poi点坐标(各设施热力效果)
export function queryHeatMapInfo(data) {
  return ajax({
    url: '/digital/facilities/queryHeatMapInfo',
    method: 'post',
    data: data
  });
}

// 地图中间的各个poi点坐标(各设施热力效果)
export function queryHourPeople(data) {
  return ajax({
    url: '/digital/facilities/queryHourPeople',
    method: 'post',
    data: data
  });
}