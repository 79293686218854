// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/images/ui-components/bg_kuang.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".el-select[data-v-15601331] {\n  width: 36.5vh;\n}\n.el-select[data-v-15601331] .el-input__inner {\n  height: 4.3vh !important;\n  line-height: 4.3vh !important;\n  background-color: transparent;\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-size: 100% 100%;\n  background-repeat: no-repeat;\n  background-position: center;\n  border-radius: 0;\n  border: none;\n  font-size: 1.9vh;\n  font-weight: 400;\n  color: #AFC8F2;\n}\n.el-select[data-v-15601331] .el-input__inner::-webkit-input-placeholder {\n  color: #AFC8F2;\n}\n.el-select[data-v-15601331] .el-input__inner::-moz-placeholder {\n  color: #AFC8F2;\n}\n.el-select[data-v-15601331] .el-input__inner:-ms-input-placeholder {\n  color: #AFC8F2;\n}\n.el-select[data-v-15601331] .el-input__inner::-ms-input-placeholder {\n  color: #AFC8F2;\n}\n.el-select[data-v-15601331] .el-input__inner::placeholder {\n  color: #AFC8F2;\n}\n.el-select[data-v-15601331].el-select__tags .el-tag {\n  background-color: #1E3458;\n  color: #AFC8F2;\n  border: 1px solid #20679E !important;\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n}\n.el-select[data-v-15601331] .el-select__caret {\n  height: 4.3vh !important;\n  line-height: 4.3vh !important;\n}", ""]);
// Exports
module.exports = exports;
