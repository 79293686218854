var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "page-chart-box AreaMap"
  }, [_c("div", {
    attrs: {
      id: "container"
    }
  }), _c("div", {
    attrs: {
      id: "container1"
    }
  }), _c("img", {
    attrs: {
      id: "img1"
    }
  }), _c("img", {
    attrs: {
      id: "img2"
    }
  }), _c("img", {
    attrs: {
      id: "img3",
      src: "https://gallerybox.makeapie.com/asset/get/s/data-1619059838735-QE9mBZmhh.png"
    }
  }), _c("div", {
    staticClass: "hover",
    style: {
      top: _vm.layerY + "px",
      left: _vm.layerX + "px",
      display: _vm.isShow ? "flex" : "none"
    }
  }, [_vm._m(0), _c("div", {
    staticClass: "info"
  }, [_c("div", {
    staticClass: "title"
  }, [_vm._v("消费偏好")]), _c("div", {
    staticClass: "tradeAreaName"
  }, [_vm._v("(" + _vm._s(_vm.tradeAreaName) + ")")]), _vm._m(1)])])]);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "icon"
  }, [_c("img", {
    attrs: {
      src: require("../../../assets/images/hoverinfo.png")
    }
  })]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "data list"
  }, [_c("div", {
    staticClass: "item"
  }, [_vm._v(" 餐饮小吃 "), _c("div", {
    staticClass: "pencentBar"
  }, [_c("div", {
    staticClass: "active"
  }), _c("div", {
    staticClass: "circle"
  })])]), _c("div", {
    staticClass: "item"
  }, [_vm._v(" 商务服务 "), _c("div", {
    staticClass: "pencentBar"
  }, [_c("div", {
    staticClass: "active"
  }), _c("div", {
    staticClass: "circle"
  })])]), _c("div", {
    staticClass: "item"
  }, [_vm._v(" 休闲娱乐 "), _c("div", {
    staticClass: "pencentBar"
  }, [_c("div", {
    staticClass: "active"
  }), _c("div", {
    staticClass: "circle"
  })])])]);
}];
render._withStripped = true;
export { render, staticRenderFns };