import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "page-container"
  }, [_c("div", {
    staticClass: "flex items-center justify-between page-header"
  }, [_c("div", {
    staticClass: "flex items-center"
  }, [_c("AreaSelect", {
    attrs: {
      clearable: false
    },
    model: {
      value: _vm.form.area,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "area", $$v);
      },
      expression: "form.area"
    }
  }), _c("StreetSelect", {
    staticClass: "ml-2",
    attrs: {
      areaCode: _vm.form.area.areaCode
    },
    model: {
      value: _vm.form.street,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "street", $$v);
      },
      expression: "form.street"
    }
  })], 1), _c("RouterMenu")], 1), _c("div", {
    staticClass: "page-content"
  }, [_c("ConvenientPointMap", {
    ref: "map",
    on: {
      finish: _vm.onMapFinished,
      cancel: _vm.onMapCancel
    }
  }), _c("ConvenientInfo", {
    ref: "ref1"
  }), _c("ConvenientStatistics", {
    ref: "ref2"
  }), _c("ConvenientDistribute", {
    ref: "ref3",
    on: {
      distributeApply: _vm.distributeApply
    }
  }), _c("ConvenientAnchor", {
    attrs: {
      streetName: _vm.form.street.name
    },
    on: {
      selected: _vm.onSelectedAnchors,
      unSelected: _vm.onClearAnchors
    }
  })], 1), _c("DistributeContainer", {
    attrs: {
      "dialog-option": _vm.distributeContainer.dialogOption,
      title: _vm.distributeContainer.title
    },
    model: {
      value: _vm.distributeContainer.showDialog,
      callback: function callback($$v) {
        _vm.$set(_vm.distributeContainer, "showDialog", $$v);
      },
      expression: "distributeContainer.showDialog"
    }
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };