var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.mapLoading,
      expression: "mapLoading"
    }],
    attrs: {
      id: "container"
    }
  });
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };