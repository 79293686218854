var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "page-chart-box home-map"
  }, [_c("div", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.mapLoading,
      expression: "mapLoading"
    }],
    attrs: {
      id: "container"
    }
  }, [_c("el-switch", {
    staticClass: "switch",
    attrs: {
      "active-text": "全国",
      "inactive-text": "城市"
    },
    on: {
      change: _vm.onModeChange
    },
    model: {
      value: _vm.isModeCountry,
      callback: function callback($$v) {
        _vm.isModeCountry = $$v;
      },
      expression: "isModeCountry"
    }
  }), _c("div", {
    staticClass: "hover",
    style: {
      top: _vm.layerY + "px",
      left: _vm.layerX + "px",
      display: _vm.isMarkerShow ? "flex" : "none"
    }
  }, [_c("div", {
    staticClass: "info"
  }, [_c("div", {
    staticClass: "tradeAreaName"
  }, [_vm._v(_vm._s(_vm.tradeAreaName))]), _c("div", {
    staticClass: "data list"
  }, [_c("div", {
    staticClass: "item"
  }, [_vm._v(" 总面积： "), _c("div", {
    staticClass: "text"
  }, [_vm._v(_vm._s(_vm.districtInfo.area))])]), _c("div", {
    staticClass: "item"
  }, [_vm._v(" 常住人口： "), _c("div", {
    staticClass: "text"
  }, [_vm._v(_vm._s(_vm.districtInfo.people))])]), _c("div", {
    staticClass: "item"
  }, [_vm._v(" 商圈监测数量： "), _c("div", {
    staticClass: "text"
  }, [_vm._v(_vm._s(_vm.districtInfo.tradeCount))])])])])])], 1), _c("div", {
    staticClass: "tabs"
  }, _vm._l(_vm.cityInfo.info, function (c) {
    return _c("div", {
      key: c.title,
      staticClass: "item",
      style: "color:".concat(c.color)
    }, [_c("div", {
      staticClass: "title"
    }, [_vm._v(_vm._s(c.title))]), _c("div", {
      staticClass: "text"
    }, [_vm._v(_vm._s(c.value))])]);
  }), 0)]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };