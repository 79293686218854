var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "page-chart-box"
  }, [_c("ModuleTitleBar", {
    attrs: {
      title: _vm.title
    }
  }), _vm._m(0)], 1);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "chart"
  }, [_c("div", {
    staticStyle: {
      width: "100%",
      height: "100%"
    },
    attrs: {
      id: "typeRatio"
    }
  })]);
}];
render._withStripped = true;
export { render, staticRenderFns };