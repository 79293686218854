var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "page-chart-box"
  }, [_c("div", {
    staticClass: "tabs"
  }, [_c("div", {
    staticClass: "item area"
  }, [_c("div", {
    staticClass: "title"
  }, [_vm._v("面积(km²)")]), _vm.results[0] ? _c("div", {
    staticClass: "text"
  }, [_vm._v(_vm._s(_vm.results[0].area))]) : _vm._e()]), _c("div", {
    staticClass: "item tradeCount"
  }, [_c("div", {
    staticClass: "title"
  }, [_vm._v("商圈监测数量(个)")]), _vm.results[0] ? _c("div", {
    staticClass: "text"
  }, [_vm._v(_vm._s(_vm.results[0].tradeCount))]) : _vm._e()])]), _c("div", {
    staticClass: "tabs"
  }, [_c("div", {
    staticClass: "item streetInfo"
  }, [_c("div", {
    staticClass: "title"
  }, [_vm._v("下辖地区")]), _vm.results[0] ? _c("div", {
    staticClass: "text"
  }, [_vm._v(_vm._s(_vm.results[0].streetInfo))]) : _vm._e()]), _c("div", {
    staticClass: "item trafficInfo"
  }, [_c("div", {
    staticClass: "title"
  }, [_vm._v("交通环境")]), _vm.results[0] ? _c("div", {
    staticClass: "text"
  }, [_vm._v(_vm._s(_vm.results[0].trafficInfo))]) : _vm._e()])])]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };