import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.iterator.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-cascader", _vm._g(_vm._b({
    attrs: {
      props: _vm.props2,
      "collapse-tags": "",
      clearable: _vm.clearable,
      placeholder: "请选择",
      separator: "",
      options: _vm.options,
      value: _vm.values
    }
  }, "el-cascader", _vm.$attrs, false), _vm.$listeners));
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };