var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "VisitorsFlowrate"
  }, [_c("div", {
    ref: "visitorsFlowrate",
    staticClass: "chart"
  })]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };