import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.push.js";
export default {
  name: 'Index',
  components: {},
  data: function data() {
    return {
      routers: []
    };
  },
  computed: {
    current: function current() {
      return this.$route.name;
    },
    menus: function menus() {
      return this.$store.state.permission.addRoutes.children;
    }
  },
  methods: {
    onMenu: function onMenu(o, index) {
      if (o.name === this.current) {
        return;
      }
      this.$router.push({
        name: o.name
      });
    }
  },
  created: function created() {},
  mounted: function mounted() {
    // console.log(this.$store.state.addRoutes);
    // this.routers = JSON.parse(cache.getMenu())
  }
};