import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.search.js";
import BusinessMap from './components/BusinessMap.vue';
import BusinessSource from './components/BusinessSource.vue';
import BusinessMoneyRatio from './components/BusinessMoneyRatio.vue';
import BusinessShareTop5 from './components/BusinessShareTop5.vue';
import BusinessTags from './components/BusinessTags.vue';
import BusinessInfo from './components/BusinessInfo.vue';
import BusinessBody from './components/BusinessBody.vue';
import BusinessDynamicTrend from './components/BusinessDynamicTrend.vue';
import BusinessSalesTrend from './components/BusinessSalesTrend.vue';
import BusinessMainShop from './components/BusinessMainShop.vue';
import BusinessSalesContainer from "@/components/commons/container/business/normal/BusinessSalesContainer";
import BusinessPeopleContainer from "@/components/commons/container/business/normal/BusinessPeopleContainer";
import BusinessMoneyRatioContainer from "@/components/commons/container/business/normal/BusinessMoneyRatioContainer";
import ShareContainer from "@/components/commons/container/business/normal/ShareContainer";
import SalesTrendContainer from "@/components/commons/container/business/normal/SalesTrendContainer";
import DynamicTrendContainer from "@/components/commons/container/business/normal/DynamicTrendContainer";
export default {
  name: 'BusinessNormal',
  components: {
    DynamicTrendContainer: DynamicTrendContainer,
    SalesTrendContainer: SalesTrendContainer,
    ShareContainer: ShareContainer,
    BusinessMoneyRatioContainer: BusinessMoneyRatioContainer,
    BusinessPeopleContainer: BusinessPeopleContainer,
    BusinessSalesContainer: BusinessSalesContainer,
    BusinessMap: BusinessMap,
    BusinessSource: BusinessSource,
    BusinessMoneyRatio: BusinessMoneyRatio,
    BusinessShareTop5: BusinessShareTop5,
    BusinessTags: BusinessTags,
    BusinessInfo: BusinessInfo,
    BusinessBody: BusinessBody,
    BusinessDynamicTrend: BusinessDynamicTrend,
    BusinessSalesTrend: BusinessSalesTrend,
    BusinessMainShop: BusinessMainShop
  },
  data: function data() {
    return {
      businessContainer: {
        sales: {
          showDialog: false,
          dialogOption: null,
          title: null
        },
        people: {
          showDialog: false,
          dialogOption: null,
          title: null
        }
      },
      moneyRatioContainer: {
        showDialog: false,
        dialogOption: null,
        title: null
      },
      shareContainer: {
        showDialog: false,
        dialogOption: null,
        title: null
      },
      salesTrendContainer: {
        showDialog: false,
        dialogOption: null,
        title: null
      },
      dynamicTrendContainer: {
        showDialog: false,
        dialogOption: null,
        title: null
      }
    };
  },
  methods: {
    dynamicTrendApply: function dynamicTrendApply(value, title) {
      this.dynamicTrendContainer.title = title;
      this.dynamicTrendContainer.dialogOption = value;
      this.dynamicTrendContainer.showDialog = true;
    },
    salesTrendApply: function salesTrendApply(value, title) {
      this.salesTrendContainer.title = title;
      this.salesTrendContainer.dialogOption = value;
      this.salesTrendContainer.showDialog = true;
    },
    shareApply: function shareApply(value, title) {
      this.shareContainer.title = title;
      this.shareContainer.dialogOption = value;
      this.shareContainer.showDialog = true;
    },
    moneyRatioApply: function moneyRatioApply(value, title) {
      this.moneyRatioContainer.title = title;
      this.moneyRatioContainer.dialogOption = value;
      this.moneyRatioContainer.showDialog = true;
    },
    businessSalesApply: function businessSalesApply(value, title) {
      this.businessContainer.sales.title = title;
      this.businessContainer.sales.dialogOption = value;
      this.businessContainer.sales.showDialog = true;
    },
    businessPeopleApply: function businessPeopleApply(value, title) {
      this.businessContainer.people.title = title;
      this.businessContainer.people.dialogOption = value;
      this.businessContainer.people.showDialog = true;
    },
    search: function search(params) {
      this.$refs.ref1.search && this.$refs.ref1.search(params);
      this.$refs.ref2.search && this.$refs.ref2.search(params);
      this.$refs.ref3.search && this.$refs.ref3.search(params);
      this.$refs.ref4.search && this.$refs.ref4.search(params);
      this.$refs.ref5.search && this.$refs.ref5.search(params);
      this.$refs.ref6.search && this.$refs.ref6.search(params);
      this.$refs.ref7.search && this.$refs.ref7.search(params);
      this.$refs.ref8.search && this.$refs.ref8.search(params);
      this.$refs.ref9.search && this.$refs.ref9.search(params);
      this.$refs.ref10.search && this.$refs.ref10.search(params);
    }
  }
};