var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "page-container"
  }, [_c("div", {
    staticClass: "flex items-center justify-between page-header"
  }, [_c("div", {
    staticClass: "flex items-center"
  }, [_c("AreaSelect", {
    attrs: {
      clearable: true
    },
    on: {
      change: _vm.onAreaChange
    },
    model: {
      value: _vm.form.area,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "area", $$v);
      },
      expression: "form.area"
    }
  }), _c("DateSelect", {
    staticClass: "ml-2",
    attrs: {
      immediate: true
    },
    model: {
      value: _vm.form.dates,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "dates", $$v);
      },
      expression: "form.dates"
    }
  })], 1), _c("RouterMenu")], 1), _c("div", {
    staticClass: "page-content"
  }, [_c("el-row", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      gutter: 15
    }
  }, [_c("el-col", {
    staticClass: "flex flex-col",
    attrs: {
      span: 14
    }
  }, [_c("el-row", {
    staticStyle: {
      "margin-left": "0"
    },
    attrs: {
      gutter: 15
    }
  }, [_c("el-col", {
    staticStyle: {
      "padding-left": "0"
    },
    attrs: {
      span: 12
    }
  }, [_c("AreaSales", {
    ref: "ref1",
    on: {
      areaSalesApply: _vm.areaSalesApply
    }
  })], 1), _c("el-col", {
    staticStyle: {
      "padding-right": "0"
    },
    attrs: {
      span: 12
    }
  }, [_c("AreaPopulation", {
    ref: "ref2",
    on: {
      areaPopulationApply: _vm.areaPopulationApply
    }
  })], 1)], 1), _c("el-row", [_c("el-col", {
    attrs: {
      span: 24
    }
  }, [_c("AreaConvert", {
    ref: "ref3",
    on: {
      areaConvertApply: _vm.areaConvertApply
    }
  })], 1)], 1)], 1), _c("el-col", {
    staticClass: "flex flex-col",
    staticStyle: {
      "margin-left": "0"
    },
    attrs: {
      span: 10
    }
  }, [_c("el-row", {
    attrs: {
      gutter: 15
    }
  }, [_c("el-col", {
    staticStyle: {
      "padding-left": "0"
    },
    attrs: {
      span: 12
    }
  }, [_c("AreaCategories", {
    ref: "ref4",
    on: {
      areaCateApply: _vm.areaCateApply
    }
  })], 1), _c("el-col", {
    staticStyle: {
      "padding-right": "0"
    },
    attrs: {
      span: 12
    }
  }, [_c("AreaBasicInformation", {
    ref: "ref6"
  })], 1)], 1), _c("AreaMap", {
    ref: "ref5"
  })], 1)], 1)], 1), _c("AreaSalesContainer", {
    attrs: {
      "dialog-option": _vm.areaSalesContainer.dialogOption,
      title: _vm.areaSalesContainer.title
    },
    model: {
      value: _vm.areaSalesContainer.showDialog,
      callback: function callback($$v) {
        _vm.$set(_vm.areaSalesContainer, "showDialog", $$v);
      },
      expression: "areaSalesContainer.showDialog"
    }
  }), _c("AreaPopulationContainer", {
    attrs: {
      "dialog-option": _vm.areaPopulationContainer.dialogOption,
      title: _vm.areaPopulationContainer.title
    },
    model: {
      value: _vm.areaPopulationContainer.showDialog,
      callback: function callback($$v) {
        _vm.$set(_vm.areaPopulationContainer, "showDialog", $$v);
      },
      expression: "areaPopulationContainer.showDialog"
    }
  }), _c("AreaConvertContainer", {
    attrs: {
      "dialog-option": _vm.areaConvertContainer.dialogOption,
      title: _vm.areaConvertContainer.title
    },
    model: {
      value: _vm.areaConvertContainer.showDialog,
      callback: function callback($$v) {
        _vm.$set(_vm.areaConvertContainer, "showDialog", $$v);
      },
      expression: "areaConvertContainer.showDialog"
    }
  }), _c("AreaCateContainer", {
    attrs: {
      "dialog-option": _vm.areaCateContainer.dialogOption,
      title: _vm.areaCateContainer.title
    },
    model: {
      value: _vm.areaCateContainer.showDialog,
      callback: function callback($$v) {
        _vm.$set(_vm.areaCateContainer, "showDialog", $$v);
      },
      expression: "areaCateContainer.showDialog"
    }
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };