import _slicedToArray from "/home/jenkins/agent/workspace/digitalnmn8f/digital-sz-web/node_modules/@babel/runtime/helpers/esm/slicedToArray.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.search.js";
import RouterMenu from '@/layout/components/RouterMenu';
import TrendIndustry from './components/TrendIndustry.vue';
import TrendActive from './components/TrendActive.vue';
import TrendForeign from './components/TrendForeign.vue';
import TrendResident from './components/TrendResident.vue';
import IndustrySelect from '@/components/commons/IndustrySelect.vue';
import DateSelect from '@/components/commons/DateSelect.vue';
import dayjs from 'dayjs';
import IndustryContainer from "@/components/commons/container/trend/IndustryContainer";
import ActiveContainer from "@/components/commons/container/trend/ActiveContainer";
import ResidentContainer from "@/components/commons/container/trend/ResidentContainer";
import ForeignContainer from "@/components/commons/container/trend/ForeignContainer";
export default {
  name: 'Trend',
  components: {
    ForeignContainer: ForeignContainer,
    ResidentContainer: ResidentContainer,
    ActiveContainer: ActiveContainer,
    IndustryContainer: IndustryContainer,
    RouterMenu: RouterMenu,
    TrendIndustry: TrendIndustry,
    TrendActive: TrendActive,
    TrendForeign: TrendForeign,
    TrendResident: TrendResident,
    DateSelect: DateSelect,
    IndustrySelect: IndustrySelect
  },
  data: function data() {
    return {
      form: {
        dates: [],
        bizTypeCodeList: [] // 不传则默认是全部行业
      },

      industryContainer: {
        showDialog: false,
        option: null,
        optionLine: null,
        optionBar: null,
        title: null,
        chartType: null
      },
      activeContainer: {
        showDialog: false,
        dialogOption: null,
        title: null
      },
      residentContainer: {
        showDialog: false,
        option: null,
        optionLine: null,
        optionBar: null,
        title: null,
        chartType: null
      },
      foreignContainer: {
        showDialog: false,
        option: null,
        optionLine: null,
        optionBar: null,
        title: null,
        chartType: null
      }
    };
  },
  methods: {
    industryApply: function industryApply(option, optionLine, optionBar, chartType, title) {
      this.industryContainer.title = title;
      this.industryContainer.chartType = chartType;
      this.industryContainer.option = option;
      this.industryContainer.optionLine = optionLine;
      this.industryContainer.optionBar = optionBar;
      this.industryContainer.showDialog = true;
    },
    activeApply: function activeApply(value, title) {
      this.activeContainer.title = title;
      this.activeContainer.dialogOption = value;
      this.activeContainer.showDialog = true;
    },
    residentApply: function residentApply(option, optionLine, optionBar, chartType, title) {
      this.residentContainer.title = title;
      this.residentContainer.chartType = chartType;
      this.residentContainer.option = option;
      this.residentContainer.optionLine = optionLine;
      this.residentContainer.optionBar = optionBar;
      this.residentContainer.showDialog = true;
    },
    foreignApply: function foreignApply(option, optionLine, optionBar, chartType, title) {
      this.foreignContainer.title = title;
      this.foreignContainer.chartType = chartType;
      this.foreignContainer.option = option;
      this.foreignContainer.optionLine = optionLine;
      this.foreignContainer.optionBar = optionBar;
      this.foreignContainer.showDialog = true;
    },
    onSearch: function onSearch() {
      var timelineList = this.form.dates.map(function (o) {
        var _o = _slicedToArray(o, 2),
          year = _o[0],
          month = _o[1];
        return dayjs("".concat(year, "-").concat(month)).format('YYYY-MM-DD 00:00:00');
      });
      var params = {};
      if (timelineList && timelineList.length > 0) {
        params.timelineList = timelineList;
      }
      if (this.form.bizTypeCodeList && this.form.bizTypeCodeList.length > 0) {
        params.bizTypeCodeList = this.form.bizTypeCodeList;
      }
      console.log('趋势监测发起搜索', params);
      // TODO:组装好的参数，通过子组件来调用,search为子组件的方法，mounted也需要在mounted主动触发一次search方法
      this.$refs.c1.search(params);
      this.$refs.c2.search(params);
      this.$refs.c3.search(params);
      this.$refs.c4.search(params);
    }
  },
  created: function created() {},
  mounted: function mounted() {}
};