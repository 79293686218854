import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isShow,
      expression: "isShow"
    }],
    staticClass: "page-chart-box"
  }, [_c("ModuleTitleBar", {
    attrs: {
      title: "便民设施统计"
    }
  }), _c("div", {
    staticClass: "box"
  }, [_vm._m(0), _vm._l(_vm.list, function (item, index) {
    return _c("div", {
      key: item.id,
      staticClass: "item"
    }, [_c("span", {
      style: {
        color: _vm.getColor(index)
      }
    }, [_vm._v(" " + _vm._s(item.name) + " ")]), _c("span", [_vm._v(" " + _vm._s(item.count) + " ")]), _c("span", [_vm._v(" " + _vm._s(item.density) + " ")])]);
  })], 2)], 1);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "item"
  }, [_c("span"), _c("span", [_vm._v("总数")]), _c("span", [_vm._v("密度(每平方公里)")])]);
}];
render._withStripped = true;
export { render, staticRenderFns };