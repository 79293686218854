import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("ul", {
    staticClass: "flex items-center justify-end"
  }, _vm._l(_vm.menus, function (o) {
    return _c("li", {
      key: o.name,
      class: {
        active: _vm.current === o.name
      },
      on: {
        click: function click($event) {
          return _vm.onMenu(o);
        }
      }
    }, [_vm._v(_vm._s(o.meta.title))]);
  }), 0);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };