var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "page-container"
  }, [_c("div", {
    staticClass: "flex justify-between items-center page-header"
  }, [_c("DateSelect", {
    attrs: {
      immediate: true
    },
    model: {
      value: _vm.dates,
      callback: function callback($$v) {
        _vm.dates = $$v;
      },
      expression: "dates"
    }
  }), _c("RouterMenu")], 1), _c("div", {
    staticClass: "page-content"
  }, [_c("el-row", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      gutter: 15
    }
  }, [_c("el-col", {
    staticClass: "flex flex-col",
    attrs: {
      span: 7
    }
  }, [_c("ConsumeStickiness", {
    attrs: {
      timeline: _vm.timeline
    },
    on: {
      stickinessBarApply: _vm.stickinessBarApply,
      stickinessPieApply: _vm.stickinessPieApply
    }
  }), _c("HomeSales", {
    attrs: {
      timeline: _vm.timeline
    },
    on: {
      homeSalesApply: _vm.homeSalesApply
    }
  }), _c("ConsumeRanking", {
    attrs: {
      timeline: _vm.timeline
    },
    on: {
      consumeRankingApply: _vm.consumeRankingApply
    }
  })], 1), _c("el-col", {
    staticClass: "flex flex-col",
    attrs: {
      span: 12
    }
  }, [_c("HomeMap", {
    attrs: {
      timeline: _vm.timeline
    }
  }), _c("ConversionRate", {
    attrs: {
      timeline: _vm.timeline
    },
    on: {
      conversionRateApply: _vm.conversionRateApply
    }
  })], 1), _c("el-col", {
    staticClass: "flex flex-col",
    attrs: {
      span: 5
    }
  }, [_c("ConsumeRatio", {
    attrs: {
      timeline: _vm.timeline
    }
  }), _c("TypeRatio", {
    attrs: {
      timeline: _vm.timeline
    },
    on: {
      typeRatioApply: _vm.typeRatioApply
    }
  }), _c("BusinessRatio", {
    attrs: {
      timeline: _vm.timeline
    },
    on: {
      businessRatioApply: _vm.businessRatioApply
    }
  })], 1)], 1)], 1), _c("ConsumeStickinessBar", {
    attrs: {
      "dialog-option": _vm.stickinessContainer.bar.dialogOption,
      title: _vm.stickinessContainer.bar.title
    },
    model: {
      value: _vm.stickinessContainer.bar.showDialog,
      callback: function callback($$v) {
        _vm.$set(_vm.stickinessContainer.bar, "showDialog", $$v);
      },
      expression: "stickinessContainer.bar.showDialog"
    }
  }), _c("ConsumeStickinessPie", {
    attrs: {
      "dialog-option": _vm.stickinessContainer.pie.dialogOption,
      title: _vm.stickinessContainer.pie.title
    },
    model: {
      value: _vm.stickinessContainer.pie.showDialog,
      callback: function callback($$v) {
        _vm.$set(_vm.stickinessContainer.pie, "showDialog", $$v);
      },
      expression: "stickinessContainer.pie.showDialog"
    }
  }), _c("HomeSalesContainer", {
    attrs: {
      "dialog-option": _vm.homeSalesContainer.dialogOption,
      title: _vm.homeSalesContainer.barTitle
    },
    model: {
      value: _vm.homeSalesContainer.showBarDialog,
      callback: function callback($$v) {
        _vm.$set(_vm.homeSalesContainer, "showBarDialog", $$v);
      },
      expression: "homeSalesContainer.showBarDialog"
    }
  }), _c("ConsumeRankingContainer", {
    attrs: {
      "dialog-option": _vm.consumeRankingContainer.dialogOption,
      title: _vm.consumeRankingContainer.barTitle
    },
    model: {
      value: _vm.consumeRankingContainer.showBarDialog,
      callback: function callback($$v) {
        _vm.$set(_vm.consumeRankingContainer, "showBarDialog", $$v);
      },
      expression: "consumeRankingContainer.showBarDialog"
    }
  }), _c("ConversionRateContainer", {
    attrs: {
      "dialog-option": _vm.conversionRateContainer.dialogOption,
      title: _vm.conversionRateContainer.barTitle
    },
    model: {
      value: _vm.conversionRateContainer.showBarDialog,
      callback: function callback($$v) {
        _vm.$set(_vm.conversionRateContainer, "showBarDialog", $$v);
      },
      expression: "conversionRateContainer.showBarDialog"
    }
  }), _c("TypeRatioContainer", {
    attrs: {
      "dialog-option": _vm.typeRatioContainer.dialogOption,
      title: _vm.typeRatioContainer.barTitle
    },
    model: {
      value: _vm.typeRatioContainer.showBarDialog,
      callback: function callback($$v) {
        _vm.$set(_vm.typeRatioContainer, "showBarDialog", $$v);
      },
      expression: "typeRatioContainer.showBarDialog"
    }
  }), _c("BusinessRatioContainer", {
    attrs: {
      "dialog-option": _vm.businessRatioContainer.dialogOption,
      title: _vm.businessRatioContainer.title
    },
    model: {
      value: _vm.businessRatioContainer.showDialog,
      callback: function callback($$v) {
        _vm.$set(_vm.businessRatioContainer, "showDialog", $$v);
      },
      expression: "businessRatioContainer.showDialog"
    }
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };