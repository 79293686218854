import _typeof from "/home/jenkins/agent/workspace/digitalnmn8f/digital-sz-web/node_modules/@babel/runtime/helpers/esm/typeof.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
export function parseTime(time, cFormat) {
  if (arguments.length === 0) {
    return null;
  }
  if ((time + '').length === 10) {
    time = +time * 1000;
  }
  var format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}';
  var date;
  if (_typeof(time) === 'object') {
    date = time;
  } else {
    date = new Date(parseInt(time));
  }
  var formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay()
  };
  return format.replace(/{(y|m|d|h|i|s|a)+}/g, function (result, key) {
    var value = formatObj[key];
    if (key === 'a') return ['一', '二', '三', '四', '五', '六', '日'][value - 1];
    if (result.length > 0 && value < 10) {
      value = '0' + value;
    }
    return value || 0;
  });
}
export function parseDate(time) {
  if (arguments.length === 0 || time == null) {
    return '';
  }
  var date = new Date(parseInt(time));
  var y = date.getFullYear();
  var m = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1;
  var d = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
  return y + '-' + m + '-' + d;
}
export function parseMonth(time) {
  if (arguments.length === 0 || time == null) {
    return '';
  }
  var date = new Date(parseInt(time));
  var y = date.getFullYear();
  var m = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1;
  return y + '年' + m + '月';
}
export function formatTime(time, option) {
  time = +time * 1000;
  var d = new Date(time);
  var now = Date.now();
  var diff = (now - d) / 1000;
  if (diff < 30) {
    return '刚刚';
  } else if (diff < 3600) {
    // less 1 hour
    return Math.ceil(diff / 60) + '分钟前';
  } else if (diff < 3600 * 24) {
    return Math.ceil(diff / 3600) + '小时前';
  } else if (diff < 3600 * 24 * 2) {
    return '1天前';
  }
  if (option) {
    return parseTime(time, option);
  } else {
    return d.getMonth() + 1 + '月' + d.getDate() + '日' + d.getHours() + '时' + d.getMinutes() + '分';
  }
}