var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "page-chart-box"
  }, [_c("ModuleTitleBar", {
    attrs: {
      title: "消费活跃度比较"
    }
  }), _c("div", {
    staticClass: "chart_box"
  }, [_c("div", {
    ref: "BusinessInfoConvience1",
    staticClass: "my_chart"
  }), _c("div", {
    ref: "BusinessInfoConvience2",
    staticClass: "my_chart"
  })]), _vm.legends.length > 1 ? _c("div", {
    staticClass: "legends"
  }, [_c("span", [_vm._v(_vm._s(_vm.legends[0]))]), _c("span", [_vm._v(_vm._s(_vm.legends[1]))])]) : _vm._e()], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };