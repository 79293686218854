var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "page-chart-box",
    style: {
      visibility: _vm.isShow ? "visible" : "hidden"
    }
  }, [_c("ModuleTitleBar", {
    attrs: {
      title: _vm.title
    }
  }), _c("div", {
    ref: "barChart",
    staticClass: "barChart"
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };