import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
// 数字以千为,格式化
export function numFormat(val) {
  if (val === null || val === undefined) {
    return 0;
  }
  if (typeof val === 'number') {
    return val.toLocaleString(); // 123,456,789
  } else {
    var reg = /(\d)(?=(?:\d{4})+$)/g;
    return val.replace(reg, '$1,'); // 1,2345,6789
  }
}