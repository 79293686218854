var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-select", _vm._g(_vm._b({
    attrs: {
      placeholder: "请选择",
      value: _vm.value,
      "value-key": "areaCode",
      "collapse-tags": ""
    }
  }, "el-select", _vm.$attrs, false), _vm.$listeners), _vm._l(_vm.list, function (item) {
    return _c("el-option", {
      key: item.areaCode,
      attrs: {
        label: item.areaName,
        value: item
      }
    });
  }), 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };