import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.search.js";
import BusinessMap from './components/BusinessMap.vue';
import BusinessSource from './components/BusinessSource.vue';
import BusinessMoneyRatio from './components/BusinessMoneyRatio.vue';
import BusinessPeopleTrend from './components/BusinessPeopleTrend.vue';
import BusinessTags from './components/BusinessTags.vue';
import BusinessActive from './components/BusinessActive.vue';
import BusinessTraffic from './components/BusinessTraffic.vue';
import BusinessDynamicShare from './components/BusinessDynamicShare.vue';
import BusinessSalesTrend from './components/BusinessSalesTrend.vue';
import SalesContainer from "@/components/commons/container/business/pk/SalesContainer";
import MoneyContainer from "@/components/commons/container/business/pk/MoneyContainer";
import PeopleContainer from "@/components/commons/container/business/pk/PeopleContainer";
import TagsContainer from "@/components/commons/container/business/pk/TagsContainer";
import SourceContainer from "@/components/commons/container/business/pk/SourceContainer";
import SourceContainer2 from "@/components/commons/container/business/pk/SourceContainer2";
import DynamicContainer from "@/components/commons/container/business/pk/DynamicContainer";
import DynamicContainer2 from "@/components/commons/container/business/pk/DynamicContainer2";
export default {
  name: 'BusinessPK',
  components: {
    DynamicContainer2: DynamicContainer2,
    DynamicContainer: DynamicContainer,
    SourceContainer2: SourceContainer2,
    SourceContainer: SourceContainer,
    TagsContainer: TagsContainer,
    PeopleContainer: PeopleContainer,
    MoneyContainer: MoneyContainer,
    SalesContainer: SalesContainer,
    BusinessMap: BusinessMap,
    BusinessSource: BusinessSource,
    BusinessMoneyRatio: BusinessMoneyRatio,
    BusinessPeopleTrend: BusinessPeopleTrend,
    BusinessTags: BusinessTags,
    BusinessActive: BusinessActive,
    BusinessTraffic: BusinessTraffic,
    BusinessDynamicShare: BusinessDynamicShare,
    BusinessSalesTrend: BusinessSalesTrend
  },
  data: function data() {
    return {
      groupNames: [],
      salesContainer: {
        showDialog: false,
        dialogOption: null,
        title: null
      },
      moneyContainer: {
        showDialog: false,
        dialogOption: null,
        title: null
      },
      peopleContainer: {
        showDialog: false,
        dialogOption: null,
        title: null
      },
      tagsContainer: {
        showDialog: false,
        dialogOption: null,
        title: null
      },
      sourceContainer: {
        showDialog: false,
        dialogOption: null,
        title: null
      },
      sourceContainer2: {
        showDialog: false,
        dialogOption: null,
        title: null
      },
      dynamicContainer2: {
        showDialog: false,
        dialogOption: null,
        title: null
      },
      dynamicContainer: {
        showDialog: false,
        dialogOption: null,
        title: null
      }
    };
  },
  methods: {
    dynamicApply2: function dynamicApply2(value, title) {
      this.dynamicContainer2.title = title;
      this.dynamicContainer2.dialogOption = value;
      this.dynamicContainer2.showDialog = true;
    },
    dynamicApply: function dynamicApply(value, title) {
      this.dynamicContainer.title = title;
      this.dynamicContainer.dialogOption = value;
      this.dynamicContainer.showDialog = true;
    },
    sourceApply: function sourceApply(value, title) {
      this.sourceContainer.title = title;
      this.sourceContainer.dialogOption = value;
      this.sourceContainer.showDialog = true;
    },
    sourceApply2: function sourceApply2(value, title) {
      this.sourceContainer2.title = title;
      this.sourceContainer2.dialogOption = value;
      this.sourceContainer2.showDialog = true;
    },
    tagsApply: function tagsApply(value, title) {
      this.tagsContainer.title = title;
      this.tagsContainer.dialogOption = value;
      this.tagsContainer.showDialog = true;
    },
    salesApply: function salesApply(value, title) {
      this.salesContainer.title = title;
      this.salesContainer.dialogOption = value;
      this.salesContainer.showDialog = true;
    },
    peopleApply: function peopleApply(value, title) {
      this.peopleContainer.title = title;
      this.peopleContainer.dialogOption = value;
      this.peopleContainer.showDialog = true;
    },
    moneyApply: function moneyApply(value, title) {
      this.moneyContainer.title = title;
      this.moneyContainer.dialogOption = value;
      this.moneyContainer.showDialog = true;
    },
    search: function search(params) {
      this.$refs.ref1.search && this.$refs.ref1.search(params);
      this.$refs.ref2.search && this.$refs.ref2.search(params);
      this.$refs.ref3.search && this.$refs.ref3.search(params);
      this.$refs.ref4.search && this.$refs.ref4.search(params);
      this.$refs.ref5.search && this.$refs.ref5.search(params);
      this.$refs.ref6.search && this.$refs.ref6.search(params);
      this.$refs.ref7.search && this.$refs.ref7.search(params);
      this.$refs.ref8.search && this.$refs.ref8.search(params);
      this.$refs.ref9.search && this.$refs.ref9.search(params);
      // if (this.form.dates.length > 0 && this.form.tradeCodes.length > 0) {
      //   const [year, month] = this.form.dates;
      //   const params = {
      //     timeline: dayjs(`${year}-${month}`).format('YYYY-MM-DD 00:00:00'),
      //     tradeAreaCode: this.form.tradeCodes[1]
      //   };
      // // TODO:组装好的参数，通过子组件来调用, 子组件的方法需暴露search方法进行接收，然后触发接口调用，请勿在mounted进行接口调用，触发时机由外层容器控制
      // // this.$refs.ref1.search && this.$refs.ref1.search(params);
      // // this.$refs.ref2.search && this.$refs.ref2.search(params);
      // // this.$refs.ref3.search && this.$refs.ref3.search(params);
      // // this.$refs.ref4.search && this.$refs.ref4.search(params);
      // // this.$refs.ref5.search && this.$refs.ref5.search(params);
      // // this.$refs.ref6.search && this.$refs.ref6.search(params);
      // }
    }
  }
};