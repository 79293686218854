var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    ref: "dom",
    staticClass: "charts"
  }, [_vm.loading ? _c("Spin", {
    attrs: {
      fix: ""
    }
  }) : _vm._e()], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };