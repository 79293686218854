import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "page-chart-box"
  }, _vm._l(_vm.list, function (item) {
    return _c("div", {
      key: item.code,
      staticClass: "item",
      class: {
        active: _vm.code === item.code
      },
      on: {
        click: function click($event) {
          return _vm.onItem(item);
        }
      }
    }, [_c("img", {
      staticClass: "logo",
      attrs: {
        src: require("@/assets/images/convenient/".concat(item.name, ".png")),
        alt: ""
      }
    }), _vm._v(" " + _vm._s(item.name) + " ")]);
  }), 0);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };