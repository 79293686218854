import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-select", _vm._g({
    attrs: {
      placeholder: "请选择",
      clearable: false,
      value: _vm.value,
      "value-key": "name",
      "collapse-tags": ""
    }
  }, _vm.$listeners), _vm._l(_vm.list, function (item) {
    return _c("el-option", {
      key: item.name,
      attrs: {
        label: item.name,
        value: item
      }
    });
  }), 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };