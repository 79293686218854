var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "page-chart-box"
  }, [_c("ModuleTitleBar", {
    attrs: {
      title: _vm.title
    }
  }), _c("div", {
    staticClass: "chart"
  }, [_c("div", {
    ref: "ConversionRate",
    staticStyle: {
      width: "70%",
      height: "100%"
    }
  }), _c("img", {
    staticClass: "coneImg",
    attrs: {
      src: require("@/assets/images/coneImg.png"),
      alt: ""
    }
  })])], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };