var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.mapLoading,
      expression: "mapLoading"
    }],
    attrs: {
      id: "container"
    }
  }, [_vm._m(0)]);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "hover",
    attrs: {
      id: "infoWindow"
    }
  }, [_c("div", {
    staticClass: "info"
  }, [_c("div", {
    staticClass: "title"
  }, [_vm._v("消费偏好")])])]);
}];
render._withStripped = true;
export { render, staticRenderFns };