var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "page-chart-box"
  }, [_c("ModuleTitleBar", {
    attrs: {
      title: _vm.title
    }
  }), _c("div", {
    staticClass: "tag_list"
  }, _vm._l(_vm.list, function (val, index) {
    return _c("div", {
      key: index + "item",
      staticClass: "tag_item"
    }, [_vm._v(" " + _vm._s(val) + " ")]);
  }), 0)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };