import ajax from '@/utils/ajax';

/* 各城市的消费粘性(输入输出)TOP5 */
export function getConsumeInfo(data) {
  return ajax({
    url: '/digital/home/getConsumeInfo',
    method: 'post',
    data: data
  });
}

/* 销售额 */
export function getSales(data) {
  return ajax({
    url: '/digital/home/getSales',
    method: 'post',
    data: data
  });
}

/* 全市消费排名 */
export function getConsumeRanking(data) {
  return ajax({
    url: '/digital/home/getConsumeRanking',
    method: 'post',
    data: data
  });
}
/* 转化率 */
export function getConvertRate(data) {
  return ajax({
    url: '/digital/home/getConvertRate',
    method: 'post',
    data: data
  });
}

/* 地图中间当前省份基本信息展示 */
export function getBaseCityInfo(data) {
  return ajax({
    url: '/digital/home/getBaseCityInfo',
    method: 'post',
    data: data
  });
}

/* 地图中间各行政区基本信息展示,暂未配置 */
export function getBaseAreaInfo() {
  return ajax({
    url: '/digital/home/getBaseAreaInfo',
    method: 'post'
  });
}

/* 全市监测商圈消费占比 */
export function getConsumeRatio(data) {
  return ajax({
    url: '/digital/home/getConsumeRatio',
    method: 'post',
    data: data
  });
}

/* 城市行业销售top5 */
export function getBizRatio(data) {
  return ajax({
    url: '/digital/home/getBizRatio',
    method: 'post',
    data: data
  });
}

/* 全市商圈消费监测占比 */
export function getTradeConsumeRatio(data) {
  return ajax({
    url: '/digital/home/getTradeConsumeRatio',
    method: 'post',
    data: data
  });
}
/* 行政区块，查询当前用户所能看到的商圈信息 */
export function queryTrade() {
  return ajax({
    url: '/digital/common/queryTrade',
    method: 'post'
  });
}